import { ChainId } from '@fox.swap/sdk'
import tokens from './tokens'
import { FarmConfig, QuoteToken } from '../types'

// Current PID is 218

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'FOXLAYER',
    image: '',
    lpAddresses: {
      [ChainId.BSC_TESTNET]: '0x4Fb99590cA95fc3255D9fA66a1cA46c43C34b09a', // Foxlayer token
      [ChainId.BSC]: '0x16b4f6cc8322f4ad478071f9babcc7ab980fd73d',
    },
    tokenSymbol: 'FOXLAYER-SPLIT',
    tokenAddresses: {
      [ChainId.BSC_TESTNET]: '0x4Fb99590cA95fc3255D9fA66a1cA46c43C34b09a', // Foxlayer Token
      [ChainId.BSC]: '0xa0d46332C75397b6f840Bd8bB7cB06bD3ff482E1',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: tokens.wbnb.address,
  },
  {
    pid: 1,
    image: '',
    lpSymbol: 'FOXLAYER-BNB',
    lpAddresses: {
      [ChainId.BSC_TESTNET]: '0x90fc86a7570063a9ea971ec74f01f89569ad6237', // FOXLAYER-BNB FoxlayerPair
      [ChainId.BSC]: '0x16b4f6cc8322f4ad478071f9babcc7ab980fd73d',
    },
    tokenSymbol: 'FOXLAYER',
    tokenAddresses: {
      [ChainId.BSC_TESTNET]: '0x4Fb99590cA95fc3255D9fA66a1cA46c43C34b09a', // Foxlayer Token
      [ChainId.BSC]: '0xa0d46332C75397b6f840Bd8bB7cB06bD3ff482E1',
    },
    style: 'featured',
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: tokens.wbnb.address,
  },
  {
    pid: 2,
    image: '',
    lpSymbol: 'FOXLAYER-BUSD',
    lpAddresses: {
      [ChainId.BSC_TESTNET]: '0xed89477d619c7e73f752d5fc7be60308ceb63663', // FOXLAYER-BUSD FoxlayerPair
      [ChainId.BSC]: '0xc8cfe58bd174c533b38027218804cb4a8d26f770',
    },
    tokenSymbol: 'FOXLAYER',
    tokenAddresses: {
      [ChainId.BSC_TESTNET]: '0x4Fb99590cA95fc3255D9fA66a1cA46c43C34b09a',
      [ChainId.BSC]: '0xa0d46332C75397b6f840Bd8bB7cB06bD3ff482E1',
    },
    style: 'featured',
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: tokens.busd.address,
  }
  
]

export default farms

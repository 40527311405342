import { JungleFarmConfig } from '../types'
import tokens from './tokens'
import { ChainId } from '@fox.swap/sdk'

const jungleFarms: JungleFarmConfig[] = [
  /*
    CHAIN ID 40 - TLOS Jungle Farms
    Current Jungle ID = 16
  */
  {
    jungleId: 1,
    tokenName: 'FOXLAYER-TLOS',
    image: 'FOXLAYER.svg',
    stakingToken: tokens.foxlayerTlos,
    rewardToken: tokens.tlos,
    lpTokens: {
      token: tokens.tlos,
      quoteToken: tokens.foxlayer,
    },
    contractAddress: {
      [ChainId.TLOS]: '0x5B7d49806709Fa0B3CdA23232cc2D609bCA2B2d0',
    },
    projectLink: 'https://telos.net',
    twitter: 'https://twitter.com/HelloTelos',
    harvest: true,
    rewardsPerSecond: '0.002396604938',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
    lpStaking: true,
    isEarnTokenLp: false,
    network: ChainId.TLOS,
  }
 
]

export default jungleFarms

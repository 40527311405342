import BigNumber from 'bignumber.js'
import { ChainId } from '@fox.swap/sdk'
import { PoolConfig, PoolCategory } from '../types'
import tokens from './tokens'

const FOXLAYER_PER_BLOCK = new BigNumber(10)

// Current Sous ID is 236

const pools: PoolConfig[] = [
  // MASTER APE V2 POOL
  {
    sousId: 0,
    tokenName: tokens.foxlayer.symbol,
    stakingToken: tokens.foxlayer,
    rewardToken: tokens.foxlayer,
    contractAddress: {
      [ChainId.BSC]: '0xe98198d52d2db020530C0D6f81BA5B01F7B02baC', // MasterApe
      [ChainId.BSC_TESTNET]: '',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://foxdex.finance',
    twitter: 'https://twitter.com/FoxchainLabs',
    audit: 'https://blocksafu.com/project-detail/0xa0d46332C75397b6f840Bd8bB7cB06bD3ff482E1',
    harvest: true,
    tokenPerBlock: `${FOXLAYER_PER_BLOCK.toNumber() * 0.25}`,
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },

  // LEGACY POOL
  // Making sousId 999 for easy configuration
  {
    sousId: 999,
    tokenName: tokens.foxlayer.symbol,
    stakingToken: tokens.foxlayer,
    rewardToken: tokens.foxlayer,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0xbbC5e1cD3BA8ED639b00927115e5f0e0040aA613', // MasterApe
      [ChainId.BSC]: '0x81Db2690B38e1603abA5cffE49347c0129bb88AF',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://foxdex.finance',
    twitter: 'https://twitter.com/FoxchainLabs',
    audit: 'https://blocksafu.com/project-detail/0xa0d46332C75397b6f840Bd8bB7cB06bD3ff482E1',
    harvest: true,
    tokenPerBlock: `${FOXLAYER_PER_BLOCK.toNumber() * 0.25}`,
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },

  {
    sousId: 21,
    tokenName: 'FOXLAYER',
    image: 'FOXLAYER.svg',
    stakingToken: tokens.glayer,
    rewardToken: tokens.foxchain,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x3996F919980778cb0fc1982087cAc01089e33fe7', // ApeRewardPool
      [ChainId.BSC]: '0x36191Bc19EC38c8F4140eed36A514aDAe4027882',
    },
    poolCategory: PoolCategory.FOXZONE,
    projectLink: 'https://foxdex.finance',
    twitter: 'https://twitter.com/FoxchainLabs',
    audit: 'https://blocksafu.com/project-detail/0xa0d46332C75397b6f840Bd8bB7cB06bD3ff482E1',
    harvest: true,
    tokenPerBlock: `0.007000000000000000`,
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },

  {
    sousId: 15,
    tokenName: 'FOXCHAIN',
    stakingToken: tokens.foxchain,
    image: 'FOXC.png',
    rewardToken: tokens.foxchain,
    contractAddress: {
      // BEP20RewardApe
      [ChainId.BSC_TESTNET]: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
      [ChainId.BSC]: '0xb4b11dfb95ec849a0205ca8a7ad6a67cbc0527ac',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://foxdex.finance',
    twitter: 'https://twitter.com/FoxchainLabs',
    audit: 'https://blocksafu.com/project-detail/0xa0d46332C75397b6f840Bd8bB7cB06bD3ff482E1',
    harvest: true,
    tokenPerBlock: '3.0000000000000000000',
    sortOrder: 3,
    isFinished: false,
    tokenDecimals: 18,
    lpStaking: false,
  },

  {
    sousId: 19,
    tokenName: 'FOXCHAIN',
    stakingToken: tokens.foxchain,
    image: 'FOXC.png',
    rewardToken: tokens.foxchain,
    contractAddress: {
      // BEP20RewardApe
      [ChainId.BSC_TESTNET]: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
      [ChainId.BSC]: '0x1a256554638e199AC00f3fCd7aCc5a867beC362b',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://foxdex.finance',
    twitter: 'https://twitter.com/FoxchainLabs',
    audit: 'https://blocksafu.com/project-detail/0xa0d46332C75397b6f840Bd8bB7cB06bD3ff482E1',
    harvest: true,
    tokenPerBlock: '0.950000000000000000',
    sortOrder: 4,
    isFinished: true,
    tokenDecimals: 18,
    lpStaking: false,
  },

  {
    sousId: 20,
    tokenName: 'FOXCHAIN',
    stakingToken: tokens.foxchain,
    image: 'FOXC.png',
    rewardToken: tokens.foxchain,
    contractAddress: {
      // BEP20RewardApe
      [ChainId.BSC_TESTNET]: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
      [ChainId.BSC]: '0x79B6B5f875ab7A16362a0272cC686e1098BfB37a',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://foxdex.finance',
    twitter: 'https://twitter.com/FoxchainLabs',
    audit: 'https://blocksafu.com/project-detail/0xa0d46332C75397b6f840Bd8bB7cB06bD3ff482E1',
    harvest: true,
    tokenPerBlock: '0.950000000000000000',
    sortOrder: 5,
    isFinished: true,
    tokenDecimals: 18,
    lpStaking: false,
  },

  {
    sousId: 22,
    tokenName: 'FOXCHAIN',
    stakingToken: tokens.foxchain,
    image: 'SHIB.png',
    rewardToken: tokens.shib,
    contractAddress: {
      // BEP20RewardApe
      [ChainId.BSC_TESTNET]: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
      [ChainId.BSC]: '0x7B9B234660b276e0a0C4bb14E4BEAdD6D40ACf3e',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://foxdex.finance',
    twitter: 'https://twitter.com/FoxchainLabs',
    audit: 'https://blocksafu.com/project-detail/0xa0d46332C75397b6f840Bd8bB7cB06bD3ff482E1',
    harvest: true,
    tokenPerBlock: '5.4500000000000000000',
    sortOrder: 6,
    isFinished: false,
    tokenDecimals: 18,
    lpStaking: false,
  },
  {
    sousId: 23,
    tokenName: 'FOXCHAIN',
    stakingToken: tokens.foxchain,
    image: 'FOXC.png',
    rewardToken: tokens.busd,
    contractAddress: {
      // BEP20RewardApe
      [ChainId.BSC_TESTNET]: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
      [ChainId.BSC]: '0x97B4B0b21104D9B98A951B067cf32855dF37C090',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://foxdex.finance',
    twitter: 'https://twitter.com/FoxchainLabs',
    audit: 'https://blocksafu.com/project-detail/0xa0d46332C75397b6f840Bd8bB7cB06bD3ff482E1',
    harvest: false,
    tokenPerBlock: '0.150000000000000000',
    sortOrder: 7,
    isFinished: true,
    tokenDecimals: 18,
    lpStaking: false,
  },

  {
    sousId: 24,
    tokenName: 'FOXCHAIN',
    stakingToken: tokens.foxchain,
    image: 'FOXC.png',
    rewardToken: tokens.foxchain,
    contractAddress: {
      // BEP20RewardApe
      [ChainId.BSC_TESTNET]: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
      [ChainId.BSC]: '0xC2B754D83b13C619F36B6d30E92BeF7FdECbaae3',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://foxdex.finance',
    twitter: 'https://twitter.com/FoxchainLabs',
    audit: 'https://blocksafu.com/project-detail/0xa0d46332C75397b6f840Bd8bB7cB06bD3ff482E1',
    harvest: true,
    tokenPerBlock: '1.0000000000000000000',
    sortOrder: 8,
    isFinished: true,
    tokenDecimals: 18,
    lpStaking: false,
  },

  {
    sousId: 25,
    tokenName: 'FOXCHAIN',
    stakingToken: tokens.foxchain,
    image: 'FOXC.png',
    rewardToken: tokens.soma,
    contractAddress: {
      // BEP20RewardApe
      [ChainId.BSC_TESTNET]: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
      [ChainId.BSC]: '0x9c44EB8d5289F1c5e3194Fa58836C8858d93d1AB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://foxdex.finance',
    twitter: 'https://twitter.com/FoxchainLabs',
    audit: 'https://blocksafu.com/project-detail/0xa0d46332C75397b6f840Bd8bB7cB06bD3ff482E1',
    harvest: true,
    tokenPerBlock: '1.4500000000000000000',
    sortOrder: 8,
    isFinished: true,
    tokenDecimals: 18,
    lpStaking: false,
  },

  {
    sousId: 26,
    tokenName: 'SOMA',
    stakingToken: tokens.soma,
    image: 'SOMA.png',
    rewardToken: tokens.foxchain,
    contractAddress: {
      // BEP20RewardApe
      [ChainId.BSC_TESTNET]: '0x93c89378fD9482e16cF6a1Aab9208d4464cc577d',
      [ChainId.BSC]: '0x93c89378fD9482e16cF6a1Aab9208d4464cc577d',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://somanetwork.io',
    twitter: 'https://twitter.com/somablockchain',
    audit: 'https://github.com/TechRate/Smart-Contract-Audits/blob/main/May_2023/SOMA%20NETWORK.pdf',
    harvest: true,
    tokenPerBlock: '1.4500000000000000000',
    sortOrder: 9,
    isFinished: true,
    tokenDecimals: 18,
    lpStaking: false,
  },

  {
    sousId: 27,
    tokenName: 'FOXCHAIN',
    stakingToken: tokens.foxchain,
    image: 'FOXC.png',
    rewardToken: tokens.horizon,
    contractAddress: {
      // BEP20RewardApe
      [ChainId.BSC_TESTNET]: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
      [ChainId.BSC]: '0x5EB45C5f73Dd154cB64A6525c4687866A5b163D9',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://foxdex.finance',
    twitter: 'https://twitter.com/FoxchainLabs',
    audit: 'https://blocksafu.com/project-detail/0xa0d46332C75397b6f840Bd8bB7cB06bD3ff482E1',
    harvest: true,
    tokenPerBlock: '1.4500000000000000000',
    sortOrder: 8,
    isFinished: false,
    tokenDecimals: 18,
    lpStaking: false,
  },

  {
    sousId: 28,
    tokenName: 'HORIZON',
    stakingToken: tokens.horizon,
    image: 'HRZ.png',
    rewardToken: tokens.foxchain,
    contractAddress: {
      // BEP20RewardApe
      [ChainId.BSC_TESTNET]: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
      [ChainId.BSC]: '0x0c72de4e4928ac32d20af40db26bc4618e50f600',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://gethorizon.netwrok',
    twitter: 'https://twitter.com/gethorizonofc',
    audit: 'https://app.solidproof.io/projects/horizon-network',
    harvest: true,
    tokenPerBlock: '1.4500000000000000000',
    sortOrder: 11,
    isFinished: false,
    tokenDecimals: 18,
    lpStaking: false,
  },
  
]

export default pools
import { ChainId } from '@fox.swap/sdk'
import tokens from './tokens'
import { BillsConfig, BillVersion } from '../types'

// BNB Largest ID: 60
// Polygon Largest ID: 106
// Telos Largest ID: 215
// Arbitrum Largest ID: 2001

const bills: BillsConfig[] = [
  {
    index: 47,
    contractAddress: {
      [ChainId.BSC]: '0xc10BD711C939f972aB7523dc1E948E5DB53B5759',
    },
    billType: 'FOXLAYER Bill',
    billVersion: BillVersion.V2,
    token: tokens.foxlayer,
    quoteToken: tokens.wbnb,
    lpToken: tokens.foxlayerBnb,
    earnToken: tokens.foxlayer,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    projectLink: 'https://foxswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    initTime: { [ChainId.BSC]: 1675904338 },
    initPrice: { [ChainId.BSC]: 0.073805 },
  },
  {
    index: 48,
    contractAddress: {
      [ChainId.BSC]: '0xDfE0C1Ee6E3383E2E50f95541881040d278720C9',
    },
    billType: 'FOXLAYER Bill',
    billVersion: BillVersion.V2,
    token: tokens.busd,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbBusd,
    earnToken: tokens.foxlayer,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    projectLink: 'https://foxswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    initTime: { [ChainId.BSC]: 1675904338 },
    initPrice: { [ChainId.BSC]: 0.073805 },
  },
  {
    index: 49,
    contractAddress: {
      [ChainId.BSC]: '0x9632D29b4786699C4262F59526D0DA0C9E7C7a72',
    },
    billType: 'FOXLAYER Bill',
    billVersion: BillVersion.V2,
    token: tokens.busd,
    quoteToken: tokens.usdc,
    lpToken: tokens.usdcBusd,
    earnToken: tokens.foxlayer,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    projectLink: 'https://foxswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    initTime: { [ChainId.BSC]: 1675904338 },
    initPrice: { [ChainId.BSC]: 0.073805 },
  },
  {
    index: 50,
    contractAddress: {
      [ChainId.BSC]: '0x1Ca83Ba8e7927e65764Ff07A61BCe8D12147CBD9',
    },
    billType: 'FOXLAYER Bill',
    billVersion: BillVersion.V2,
    token: tokens.eth,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbEth,
    earnToken: tokens.foxlayer,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    projectLink: 'https://foxswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    initTime: { [ChainId.BSC]: 1675904338 },
    initPrice: { [ChainId.BSC]: 0.073805 },
  },
  {
    index: 51,
    contractAddress: {
      [ChainId.BSC]: '0xAB60c836793e04DDA3168fCd4e36AF52Bf6e65Cf',
    },
    billType: 'FOXLAYER Bill',
    billVersion: BillVersion.V2,
    token: tokens.btc,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbBtc,
    earnToken: tokens.foxlayer,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    projectLink: 'https://foxswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    initTime: { [ChainId.BSC]: 1675904338 },
    initPrice: { [ChainId.BSC]: 0.073805 },
  },
  {
    index: 60,
    contractAddress: {
      [ChainId.BSC]: '0xc3111525f0CeEB7565939c8Da18D9cDD67C7B219',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V2,
    token: tokens.monsta,
    quoteToken: tokens.wbnb,
    lpToken: tokens.monstaBnb,
    earnToken: tokens.monsta,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    projectLink: 'https://cake.monster/',
    twitter: 'https://twitter.com/thecakemnstr',
    audit: 'https://solidity.finance/audits/CakeMonster/',
    initTime: { [ChainId.BSC]: 1680097097 },
    initPrice: { [ChainId.BSC]: 0.00100443 },
  },
  {
    index: 59,
    contractAddress: {
      [ChainId.BSC]: '0x83a9d9c278f2ccE356667EbdAD20c9B4BA68d5aE',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V2,
    token: tokens.credi,
    quoteToken: tokens.wbnb,
    lpToken: tokens.crediBnb,
    earnToken: tokens.credi,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    projectLink: 'https://www.credefi.finance/',
    twitter: 'https://twitter.com/credefi_finance/',
    audit: 'https://www.certik.com/projects/credefi?utm_source=CMC&utm_campaign=AuditByCertiKLink',
    initTime: { [ChainId.BSC]: 1678922163 },
    initPrice: { [ChainId.BSC]: 0.00251252 },
  },
  {
    index: 58,
    contractAddress: {
      [ChainId.BSC]: '0x9b86b2baE5B9C7eD73264Cbfdf1b819496Fdc091',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V2,
    token: tokens.cgg,
    quoteToken: tokens.usdc,
    lpToken: tokens.cggUsdc,
    earnToken: tokens.usdc,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    projectLink: 'https://chainguardians.io/',
    twitter: 'https://twitter.com/chain_guardians',
    audit: 'https://www.certik.com/projects/chainguardians?utm_source=CMC&utm_campaign=AuditByCertiKLink',
    initTime: { [ChainId.BSC]: 1678269604 },
    initPrice: { [ChainId.BSC]: 0.110177 },
  },
  {
    index: 57,
    contractAddress: {
      [ChainId.BSC]: '0x31CA3536a7B3560e56a0BEA226135Cf809D2E237',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V2,
    token: tokens.lgc,
    quoteToken: tokens.wbnb,
    lpToken: tokens.lgcBnb,
    earnToken: tokens.lgc,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    projectLink: 'http://livegreencoin.com/',
    twitter: 'https://twitter.com/LiveGreenCoin1',
    audit: 'https://www.certik.com/projects/livegreencoin',
    initTime: { [ChainId.BSC]: 1678053061 },
    initPrice: { [ChainId.BSC]: 0.00927457 },
  },
  {
    index: 56,
    contractAddress: {
      [ChainId.BSC]: '0xCdCC22C01e1660713B45fE83d7C23787792931B5',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V2,
    token: tokens.oath,
    quoteToken: tokens.usdc,
    lpToken: tokens.oathUsdc,
    earnToken: tokens.oath,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    projectLink: 'https://www.reaper.farm/',
    twitter: 'https://twitter.com/Reaper_Farm',
    audit: 'https://solidity.finance/audits/ReaperFarm/',
    initTime: { [ChainId.BSC]: 1677676077 },
    initPrice: { [ChainId.BSC]: 0.2253 },
  },
  {
    index: 55,
    contractAddress: {
      [ChainId.BSC]: '0x4E96152511D29c99c47A24B07a3b98738e0fb9BA',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V2,
    token: tokens.toko,
    quoteToken: tokens.wbnb,
    lpToken: tokens.tokoBnb,
    earnToken: tokens.toko,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    projectLink: 'https://tokoin.io/',
    twitter: 'https://twitter.com/tokoinglobal',
    audit: 'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-T-Fi-v1.0.pdf',
    initTime: { [ChainId.BSC]: 1677184302 },
    initPrice: { [ChainId.BSC]: 0.00434657 },
  },
  {
    index: 54,
    contractAddress: {
      [ChainId.BSC]: '0x60219D7ABd2381230BC250548109b464f44e44dC',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V2,
    token: tokens.cvl,
    quoteToken: tokens.usdt,
    lpToken: tokens.cvlUsdt,
    earnToken: tokens.cvl,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    projectLink: 'https://cvl.network',
    twitter: 'https://twitter.com/cvl_network',
    audit: 'https://github.com/xdao-app/xdao-contracts/blob/master/audits/hacken/hacken.pdf',
    initTime: { [ChainId.BSC]: 1677080938 },
    initPrice: { [ChainId.BSC]: 0.00864348 },
  },
  {
    index: 53,
    contractAddress: {
      [ChainId.BSC]: '0xF3DADCFa7114652Ca750e0C653A00DeCB63B4d0F',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V2,
    token: tokens.trivia,
    quoteToken: tokens.wbnb,
    lpToken: tokens.triviaBnb,
    earnToken: tokens.trivia,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    projectLink: 'https://trivians.io/',
    twitter: 'https://twitter.com/PlayTrivians',
    audit: 'https://hacken.io/wp-content/uploads/2022/06/Trivians_29062022_SCAudit_Report_2.pdf',
    initTime: { [ChainId.BSC]: 1676467044 },
    initPrice: { [ChainId.BSC]: 0.00342517 },
  },
  {
    index: 52,
    contractAddress: {
      [ChainId.BSC]: '0x6ed6Ccf1f55f3C42438265d1c1C58B9e6756fb1a',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V2,
    token: tokens.xtal,
    quoteToken: tokens.busd,
    lpToken: tokens.xtalBusd,
    earnToken: tokens.xtal,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    projectLink: 'https://talesofxtalnia.com/',
    twitter: 'https://twitter.com/xtalnia',
    audit: 'https://github.com/HashEx/public_audits/blob/master/XTAL%20Token/XTAL%20Token.pdf',
    initTime: { [ChainId.BSC]: 1676322242 },
    initPrice: { [ChainId.BSC]: 0.438389 },
  },
  {
    index: 46,
    contractAddress: {
      [ChainId.BSC]: '0x12c42b96ff1248471bbc9da06d7fee828e6f7db2',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.wcflt,
    quoteToken: tokens.wbnb,
    lpToken: tokens.wcfltBnb,
    earnToken: tokens.wcflt,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://www.coinflect.com/',
    twitter: 'https://twitter.com/coinflect?lang=en',
    audit:
      'https://github.com/coinflect/wrapped-coinflect-audit/blob/main/Wrapped%20Coinflect%20Smart%20Contract%20Security%20Audit.pdf',
    initTime: { [ChainId.BSC]: 1675861237 },
    initPrice: { [ChainId.BSC]: 0.00004566 },
  },
  {
    index: 45,
    contractAddress: {
      [ChainId.BSC]: '0x44c2211976f796d439e82036006d9559c1b1fbdb',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.avan,
    quoteToken: tokens.wbnb,
    lpToken: tokens.avanBnb,
    earnToken: tokens.avan,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://avanatoken.com/',
    twitter: 'https://twitter.com/AvanaProject',
    audit: 'https://hashex.org/audits/avana-token/',
    initTime: { [ChainId.BSC]: 1675687946 },
    initPrice: { [ChainId.BSC]: 0.029782 },
  },
  {
    index: 43,
    contractAddress: {
      [ChainId.BSC]: '0x279c47aE7846962037077338505c657ad65D0DeE',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.liq,
    quoteToken: tokens.busd,
    lpToken: tokens.liqBusd,
    earnToken: tokens.liq,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://liquidus.finance/',
    twitter: 'https://twitter.com/LiquidusFinance',
    audit: 'https://liquidus.finance/LiqTechRateAudit.pdf',
    initTime: { [ChainId.BSC]: 1674588602 },
    initPrice: { [ChainId.BSC]: 0.367212 },
  },
  {
    index: 44,
    contractAddress: {
      [ChainId.BSC]: '0xaf7F491e77f4979faadbE24866D4471De23bd0a0',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.monsta,
    quoteToken: tokens.wbnb,
    lpToken: tokens.monstaBnb,
    earnToken: tokens.monsta,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://cake.monster/',
    twitter: 'https://twitter.com/thecakemnstr',
    audit: 'https://solidity.finance/audits/CakeMonster/',
    initTime: { [ChainId.BSC]: 1674763153 },
    initPrice: { [ChainId.BSC]: 0.000919952 },
  },
  {
    index: 37,
    contractAddress: {
      [ChainId.BSC]: '0x2e9bdb018850747dc557d13046b8259ab24c674a',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.radar,
    quoteToken: tokens.wbnb,
    lpToken: tokens.radarBnb,
    earnToken: tokens.radar,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://dappradar.com/',
    twitter: 'https://twitter.com/DappRadar/',
    audit: 'https://dappradar.com/documents/RADAR-Audits-v1.pdf',
    initTime: { [ChainId.BSC]: 1668620528 },
    initPrice: { [ChainId.BSC]: 0.005311928477767259 },
  },
  {
    index: 38,
    contractAddress: {
      [ChainId.BSC]: '0x5aabc088b42c197c8da69af5471aa06c3852912b',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.ihc,
    quoteToken: tokens.wbnb,
    lpToken: tokens.ihcBnb,
    earnToken: tokens.ihc,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://www.ihcoin.io/',
    twitter: 'https://twitter.com/IHCoinofficial',
    audit: 'https://www.certik.com/projects/ihc#audit',
    initTime: { [ChainId.BSC]: 1669045328 },
    initPrice: { [ChainId.BSC]: 0.0000773918161395094 },
  },
  {
    index: 39,
    contractAddress: {
      [ChainId.BSC]: '0xfe69f6f456fdc5535e7a7a03bb78f739400bc8e0',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.liq,
    quoteToken: tokens.busd,
    lpToken: tokens.liqBusd,
    earnToken: tokens.liq,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://liquidus.finance/',
    twitter: 'https://twitter.com/LiquidusFinance',
    initTime: { [ChainId.BSC]: 1669794128 },
    initPrice: { [ChainId.BSC]: 0.3590438711755946 },
  },
  {
    index: 40,
    contractAddress: {
      [ChainId.BSC]: '0x74dab17e30b974a3590796a8ba2223a50da6a32c',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.pstn,
    quoteToken: tokens.busd,
    lpToken: tokens.pstnBusd,
    earnToken: tokens.pstn,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://piston-token.com/',
    twitter: 'https://mobile.twitter.com/pistontoken',
    initTime: { [ChainId.BSC]: 1669905728 },
    initPrice: { [ChainId.BSC]: 2.000004210715095 },
  },
  {
    index: 41,
    contractAddress: {
      [ChainId.BSC]: '0xEd242A13d25b5B2270E90058C9a1A756811cdb93',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.gq,
    quoteToken: tokens.busd,
    lpToken: tokens.gqBusd,
    earnToken: tokens.gq,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://outerringmmo.com/',
    twitter: 'https://twitter.com/OuterRingMMO',
    initTime: { [ChainId.BSC]: 1670432974 },
    initPrice: { [ChainId.BSC]: 0.001714637510619517 },
  },
  {
    index: 42,
    contractAddress: {
      [ChainId.BSC]: '0xb95bd2dc93562fd9076c0573615de42b413db256',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.leap,
    quoteToken: tokens.wbnb,
    lpToken: tokens.leapBnb,
    earnToken: tokens.leap,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://www.leaptoken.io/',
    twitter: 'https://twitter.com/Play_Leap',
    audit: 'https://github.com/HashEx/public_audits/blob/master/PlayLeap/PlayLeap.pdf',
    initTime: { [ChainId.BSC]: 1673368322 },
    initPrice: { [ChainId.BSC]: 0.165579 },
  },
  {
    index: 30,
    contractAddress: {
      [ChainId.BSC]: '0xced994206cd4c364d7bda2b66b48fcaef9b6bb5b',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.nfty,
    quoteToken: tokens.busd,
    lpToken: tokens.nftyBusd,
    earnToken: tokens.nfty,
    billNnftAddress: {
      [ChainId.BSC]: '0x7fd203888661d1f0ae625ed599909f8f97698670',
    },
    projectLink: 'https://nftynetwork.io/',
    twitter: 'https://twitter.com/NFTYFinance',
    initTime: { [ChainId.BSC]: 1667839328 },
    initPrice: { [ChainId.BSC]: 0.005420113056475245 },
  },
  {
    index: 29,
    contractAddress: {
      [ChainId.BSC]: '0x7aa1939b8284a2defdcb5e983de55da2ad61f607',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.threeair,
    quoteToken: tokens.wbnb,
    lpToken: tokens.threeairBnb,
    earnToken: tokens.threeair,
    billNnftAddress: {
      [ChainId.BSC]: '0x7fd203888661d1f0ae625ed599909f8f97698670',
    },
    projectLink: 'https://3air.io/',
    twitter: 'https://twitter.com/3aircrypto',
    audit: 'https://github.com/JorgeRodriguezsec/CTDsec/blob/main/Audits/Cybersecurity_Audit_CTDSEC_3air.pdf',
    initTime: { [ChainId.BSC]: 1667512328 },
    initPrice: { [ChainId.BSC]: 0.04540120370089731 },
  },
  {
    index: 27,
    contractAddress: {
      [ChainId.BSC]: '0x626b5c18a2b702a189ea022df897a4f0f3566785',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.coc,
    quoteToken: tokens.wbnb,
    lpToken: tokens.cocBnb,
    earnToken: tokens.coc,
    billNnftAddress: {
      [ChainId.BSC]: '0x7fd203888661d1f0ae625ed599909f8f97698670',
    },
    projectLink: 'https://www.coinofchampions.com/',
    twitter: 'https://twitter.com/coinofchampions',
    initTime: { [ChainId.BSC]: 1667352248 },
    initPrice: { [ChainId.BSC]: 1.2566456303843458e-7 },
  },
  {
    index: 22,
    contractAddress: {
      [ChainId.BSC]: '0x7b1dfe9ca71ffeac5ed4d1beb592771e3672a2de',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.chrp,
    quoteToken: tokens.wbnb,
    lpToken: tokens.chrpBnb,
    earnToken: tokens.chrp,
    billNnftAddress: {
      [ChainId.BSC]: '0x7fd203888661d1f0ae625ed599909f8f97698670',
    },
    projectLink: 'https://chirpley.ai',
    twitter: 'https://twitter.com/chirpley',
    audit: 'https://stack.chirpley.nl/s/241c8gWBtVHnuZO1',
    initTime: { [ChainId.BSC]: 1664883258 },
    initPrice: { [ChainId.BSC]: 0.006475439494480084 },
  },
  {
    index: 20,
    contractAddress: {
      [ChainId.BSC]: '0xb646c4a51365598433ba4765b890ad3075e4e21e',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.gmr,
    quoteToken: tokens.wbnb,
    lpToken: tokens.gmrBnb,
    earnToken: tokens.gmr,
    billNnftAddress: {
      [ChainId.BSC]: '0x7fd203888661d1f0ae625ed599909f8f97698670',
    },
    projectLink: 'https://gmr.center/',
    twitter: 'https://twitter.com/GMRCenter',
    audit: 'https://www.certik.com/projects/gamer',
    initTime: { [ChainId.BSC]: 1662032058 },
    initPrice: { [ChainId.BSC]: 0.019076325851224644 },
  },
  {
    index: 31,
    contractAddress: {
      [ChainId.BSC]: '0x63571f457f246124c6968579E8797B91613dED20',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.xcur,
    quoteToken: tokens.busd,
    lpToken: tokens.xcurBusd,
    earnToken: tokens.xcur,
    billNnftAddress: {
      [ChainId.BSC]: '0x7fd203888661d1f0ae625ed599909f8f97698670',
    },
    projectLink: 'https://web.curate.style/',
    twitter: 'https://twitter.com/curateproject',
    initTime: { [ChainId.BSC]: 1662032058 },
    initPrice: { [ChainId.BSC]: 0.019076325851224644 },
  },
  {
    index: 28,
    contractAddress: {
      [ChainId.BSC]: '0x7fdb62f681d7aad8c08a1d6af350e2fb7f265336',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.gq,
    quoteToken: tokens.busd,
    lpToken: tokens.gqBusd,
    earnToken: tokens.gq,
    billNnftAddress: {
      [ChainId.BSC]: '0x7fd203888661d1f0ae625ed599909f8f97698670',
    },
    projectLink: 'https://outerringmmo.com/',
    twitter: 'https://twitter.com/OuterRingMMO',
    initTime: { [ChainId.BSC]: 1667483648 },
    initPrice: { [ChainId.BSC]: 0.002164726526133125 },
    inactive: true,
  },
  {
    index: 26,
    contractAddress: {
      [ChainId.BSC]: '0x07968648273e29f5e2fe07aabf5fbc3b40892592',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.pstn,
    quoteToken: tokens.busd,
    lpToken: tokens.pstnBusd,
    earnToken: tokens.pstn,
    billNnftAddress: {
      [ChainId.BSC]: '0x7fd203888661d1f0ae625ed599909f8f97698670',
    },
    projectLink: 'https://piston-token.com/',
    twitter: 'https://twitter.com/MyDefi_team',
    inactive: true,
  },
  {
    index: 25,
    contractAddress: {
      [ChainId.BSC]: '0xff674209beeb493f9e8c44898848c6cfd710e98f',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.xwin,
    quoteToken: tokens.wbnb,
    lpToken: tokens.xwinBnb,
    earnToken: tokens.xwin,
    billNnftAddress: {
      [ChainId.BSC]: '0x7fd203888661d1f0ae625ed599909f8f97698670',
    },
    projectLink: 'https://xwin.finance/',
    twitter: 'https://twitter.com/xwinfinance',
    initTime: { [ChainId.BSC]: 1666784058 },
    initPrice: { [ChainId.BSC]: 0.550605316141175 },
  },
  {
    index: 24,
    contractAddress: {
      [ChainId.BSC]: '0x2f8042167b0549ef56e55b9c1ace661b10ad93ea',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.froyo,
    quoteToken: tokens.wbnb,
    lpToken: tokens.froyoBnb,
    earnToken: tokens.froyo,
    billNnftAddress: {
      [ChainId.BSC]: '0x7fd203888661d1f0ae625ed599909f8f97698670',
    },
    projectLink: 'https://froyo.games/',
    twitter: 'https://twitter.com/realfroyogames',
    initTime: { [ChainId.BSC]: 1666092858 },
    initPrice: { [ChainId.BSC]: 0.010365834030975409 },
  },
  {
    index: 23,
    contractAddress: {
      [ChainId.BSC]: '0xc117e57f4fa2fc1c2759228e0f85bc6d1c89be36',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.froyo,
    quoteToken: tokens.wbnb,
    lpToken: tokens.froyoBnb,
    earnToken: tokens.froyo,
    billNnftAddress: {
      [ChainId.BSC]: '0x7fd203888661d1f0ae625ed599909f8f97698670',
    },
    inactive: true,
    projectLink: 'https://froyo.games/',
    twitter: 'https://twitter.com/realfroyogames',
  },
  {
    index: 21,
    contractAddress: {
      [ChainId.BSC]: '0x7e7842f762b27a024f7c3b95495f1d132c554d6b',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.blid,
    quoteToken: tokens.usdt,
    lpToken: tokens.blidUsdt,
    earnToken: tokens.blid,
    billNnftAddress: {
      [ChainId.BSC]: '0x7fd203888661d1f0ae625ed599909f8f97698670',
    },
    projectLink: 'https://bolide.fi/',
    twitter: 'https://twitter.com/Bolide_fi',
    initTime: { [ChainId.BSC]: 1664192058 },
    initPrice: { [ChainId.BSC]: 0.024065964757317017 },
  },
  {
    index: 19,
    contractAddress: {
      [ChainId.BSC]: '0x99eea57cdc8983992a3c1505dbf7181467daa22f',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.hec,
    quoteToken: tokens.wbnb,
    lpToken: tokens.hecBnb,
    earnToken: tokens.hec,
    billNnftAddress: {
      [ChainId.BSC]: '0x7fd203888661d1f0ae625ed599909f8f97698670',
    },
    projectLink: 'https://hector.network/',
    twitter: 'https://twitter.com/Hector_Network',
    initTime: { [ChainId.BSC]: 1660649658 },
    initPrice: { [ChainId.BSC]: 18.15486755163556 },
  },
  {
    index: 18,
    contractAddress: {
      [ChainId.BSC]: '0x80ef5c5215ff56a12b5e59c2acfc279c763ae34b',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.floki,
    quoteToken: tokens.wbnb,
    lpToken: tokens.flokiBnb,
    earnToken: tokens.floki,
    billNnftAddress: {
      [ChainId.BSC]: '0x7fd203888661d1f0ae625ed599909f8f97698670',
    },
    projectLink: 'https://www.floki.com/',
    twitter: 'https://twitter.com/realflokiinu',
    initTime: { [ChainId.BSC]: 1660217658 },
    initPrice: { [ChainId.BSC]: 0.00001504435067868268 },
  },
  {
    index: 12,
    contractAddress: {
      [ChainId.BSC]: '0x3f02ce456b9da36352439fbd1897c2233a77e46f',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.radar,
    quoteToken: tokens.wbnb,
    lpToken: tokens.radarBnb,
    earnToken: tokens.radar,
    billNnftAddress: {
      [ChainId.BSC]: '0xb0278e43DbD744327FE0d5D0ABa4A77CBFC7fad8',
    },
    projectLink: 'https://dappradar.com/',
    twitter: 'https://twitter.com/DappRadar/',
    inactive: true,
  },
  {
    index: 5,
    contractAddress: {
      [ChainId.BSC]: '0x1c36913ca7c64365a54694cd91dde9c7cf5e44ec',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.ceek,
    quoteToken: tokens.wbnb,
    lpToken: tokens.ceekBnb,
    earnToken: tokens.ceek,
    billNnftAddress: {
      [ChainId.BSC]: '0xb0278e43DbD744327FE0d5D0ABa4A77CBFC7fad8',
    },
    projectLink: 'https://ceek.com/',
    twitter: 'https://twitter.com/CEEK/',
    initTime: { [ChainId.BSC]: 1655292858 },
    initPrice: { [ChainId.BSC]: 0.12499413563790174 },
  },
  {
    index: 6,
    contractAddress: {
      [ChainId.BSC]: '0xd9e18fae215f10f4ce35a24fcbfe944fbfb96643',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.nfty,
    quoteToken: tokens.wbnb,
    lpToken: tokens.nftyBnb,
    earnToken: tokens.nfty,
    billNnftAddress: {
      [ChainId.BSC]: '0xb0278e43DbD744327FE0d5D0ABa4A77CBFC7fad8',
    },
    projectLink: 'https://nftynetwork.io/',
    twitter: 'https://twitter.com/NFTYLab',
    inactive: true,
  },
  {
    index: 7,
    contractAddress: {
      [ChainId.BSC]: '0xda0149da023cb204b3ffba33a88d9dd19393c6fc',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.tlos,
    quoteToken: tokens.busd,
    lpToken: tokens.tlosBusd,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.BSC]: '0xb0278e43DbD744327FE0d5D0ABa4A77CBFC7fad8',
    },
    inactive: true,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
  },
  {
    index: 8,
    contractAddress: {
      [ChainId.BSC]: '0xaf3016100f67c66af15c973cb8da94962653a726',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.stars,
    quoteToken: tokens.wbnb,
    lpToken: tokens.starsBnb,
    earnToken: tokens.stars,
    billNnftAddress: {
      [ChainId.BSC]: '0xb0278e43DbD744327FE0d5D0ABa4A77CBFC7fad8',
    },
    projectLink: 'https://www.mogulproductions.com/',
    twitter: 'https://twitter.com/mogulofficial_',
    initTime: { [ChainId.BSC]: 1655292858 },
    initPrice: { [ChainId.BSC]: 0.012596738432285355 },
  },
  {
    index: 9,
    contractAddress: {
      [ChainId.BSC]: '0x6a5be77e3fc1c99ef4aae2846432d61fa78a564c',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.hotcross,
    quoteToken: tokens.busd,
    lpToken: tokens.hotcrossBusd,
    earnToken: tokens.hotcross,
    billNnftAddress: {
      [ChainId.BSC]: '0xb0278e43DbD744327FE0d5D0ABa4A77CBFC7fad8',
    },
    projectLink: 'https://hotcross.com/',
    twitter: 'https://twitter.com/hotcrosscom',
    initTime: { [ChainId.BSC]: 1655292858 },
    initPrice: { [ChainId.BSC]: 0.0410817844906574 },
  },
  {
    index: 10,
    contractAddress: {
      [ChainId.BSC]: '0xb5fd0ac23267da501fc6c59c6e12ae32831e72bb',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.hotcross,
    quoteToken: tokens.busd,
    lpToken: tokens.hotcrossBusd,
    earnToken: tokens.hotcross,
    billNnftAddress: {
      [ChainId.BSC]: '0xb0278e43DbD744327FE0d5D0ABa4A77CBFC7fad8',
    },
    projectLink: 'https://hotcross.com/',
    twitter: 'https://twitter.com/hotcrosscom',
    initTime: { [ChainId.BSC]: 1655292858 },
    initPrice: { [ChainId.BSC]: 0.0410817844906574 },
  },
  {
    index: 11,
    contractAddress: {
      [ChainId.BSC]: '0x4a3adf34b1f8830fb8d673477d52fa5cb91a2531',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V1,
    token: tokens.tlos,
    quoteToken: tokens.busd,
    lpToken: tokens.tlosBusd,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.BSC]: '0xb0278e43DbD744327FE0d5D0ABa4A77CBFC7fad8',
    },
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
    initTime: { [ChainId.BSC]: 1655552058 },
    initPrice: { [ChainId.BSC]: 0.1736239460283216 },
  },
  // Old FOXLAYER Bills
  {
    index: 0,
    contractAddress: {
      [ChainId.BSC]: '0x8b57Fc5BE65118188D50d42fcD5614e447F7FAbE',
    },
    billType: 'FOXLAYER Bill',
    billVersion: BillVersion.V1,
    token: tokens.foxlayer,
    quoteToken: tokens.wbnb,
    lpToken: tokens.foxlayerBnb,
    earnToken: tokens.foxlayer,
    billNnftAddress: {
      [ChainId.BSC]: '0xb0278e43DbD744327FE0d5D0ABa4A77CBFC7fad8',
    },
    inactive: true,
    projectLink: 'https://foxswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
  },
  {
    index: 1,
    contractAddress: {
      [ChainId.BSC]: '0x4925AcdE0E885170801A74DEBcC8fbA91F3aE29b',
    },
    billType: 'FOXLAYER Bill',
    billVersion: BillVersion.V1,
    token: tokens.busd,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbBusd,
    earnToken: tokens.foxlayer,
    billNnftAddress: {
      [ChainId.BSC]: '0xb0278e43DbD744327FE0d5D0ABa4A77CBFC7fad8',
    },
    inactive: true,
    projectLink: 'https://foxswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
  },
  {
    index: 2,
    contractAddress: {
      [ChainId.BSC]: '0xca1612f66292398a5df0ecadd98bb81dc264349d',
    },
    billType: 'FOXLAYER Bill',
    billVersion: BillVersion.V1,
    token: tokens.busd,
    quoteToken: tokens.usdc,
    lpToken: tokens.usdcBusd,
    earnToken: tokens.foxlayer,
    billNnftAddress: {
      [ChainId.BSC]: '0xb0278e43DbD744327FE0d5D0ABa4A77CBFC7fad8',
    },
    inactive: true,
    projectLink: 'https://foxswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
  },
  {
    index: 3,
    contractAddress: {
      [ChainId.BSC]: '0xb2d516086BFc978950e40D2739c72125415441a8',
    },
    billType: 'FOXLAYER Bill',
    billVersion: BillVersion.V1,
    token: tokens.eth,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbEth,
    earnToken: tokens.foxlayer,
    billNnftAddress: {
      [ChainId.BSC]: '0xb0278e43DbD744327FE0d5D0ABa4A77CBFC7fad8',
    },
    inactive: true,
    projectLink: 'https://foxswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
  },
  {
    index: 4,
    contractAddress: {
      [ChainId.BSC]: '0xBD9959320cbbC69b2eF7d07fb3f9870cceaeB44f',
    },
    billType: 'FOXLAYER Bill',
    billVersion: BillVersion.V1,
    token: tokens.btc,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbBtc,
    earnToken: tokens.foxlayer,
    billNnftAddress: {
      [ChainId.BSC]: '0xb0278e43DbD744327FE0d5D0ABa4A77CBFC7fad8',
    },
    inactive: true,
    projectLink: 'https://foxswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
  },
  {
    index: 13,
    contractAddress: {
      [ChainId.BSC]: '0xE325439439D692DC858Ba887601999D57d35688F',
    },
    billType: 'FOXLAYER Bill',
    billVersion: BillVersion.V1,
    token: tokens.foxlayer,
    quoteToken: tokens.wbnb,
    lpToken: tokens.foxlayerBnb,
    earnToken: tokens.foxlayer,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://foxswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    inactive: true,
  },
  {
    index: 14,
    contractAddress: {
      [ChainId.BSC]: '0xf297F71f4664cF7F1Cd5d90720967998A1163cC3',
    },
    billType: 'FOXLAYER Bill',
    billVersion: BillVersion.V1,
    token: tokens.busd,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbBusd,
    earnToken: tokens.foxlayer,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://foxswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    inactive: true,
  },
  {
    index: 15,
    contractAddress: {
      [ChainId.BSC]: '0x937A30Fd88f48B23DeABf4fD43c42cc9d3f3F9Dd',
    },
    billType: 'FOXLAYER Bill',
    billVersion: BillVersion.V1,
    token: tokens.busd,
    quoteToken: tokens.usdc,
    lpToken: tokens.usdcBusd,
    earnToken: tokens.foxlayer,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://foxswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    inactive: true,
  },
  {
    index: 16,
    contractAddress: {
      [ChainId.BSC]: '0x2D375a67366eDFB92F520aE428E8E08451D638d2',
    },
    billType: 'FOXLAYER Bill',
    billVersion: BillVersion.V1,
    token: tokens.eth,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbEth,
    earnToken: tokens.foxlayer,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://foxswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    inactive: true,
  },
  {
    index: 17,
    contractAddress: {
      [ChainId.BSC]: '0x44921f493887ab8a8b9db54db335f65c1ef8d51a',
    },
    billType: 'FOXLAYER Bill',
    billVersion: BillVersion.V1,
    token: tokens.btc,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbBtc,
    earnToken: tokens.foxlayer,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://foxswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    inactive: true,
  },
  {
    index: 32,
    contractAddress: {
      [ChainId.BSC]: '0xa45dc4b9743d489e9b91070b27e72094b697632d',
    },
    billType: 'FOXLAYER Bill',
    billVersion: BillVersion.V1,
    token: tokens.foxlayer,
    quoteToken: tokens.wbnb,
    lpToken: tokens.foxlayerBnb,
    earnToken: tokens.foxlayer,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://foxswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    initTime: { [ChainId.BSC]: 1668467108 },
    initPrice: { [ChainId.BSC]: 0.0709352845097292 },
    inactive: true,
  },
  {
    index: 33,
    contractAddress: {
      [ChainId.BSC]: '0x3a4de56b96d5befd0c9f3fb214372699f2d58947',
    },
    billType: 'FOXLAYER Bill',
    billVersion: BillVersion.V1,
    token: tokens.busd,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbBusd,
    earnToken: tokens.foxlayer,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://foxswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    initTime: { [ChainId.BSC]: 1668467108 },
    initPrice: { [ChainId.BSC]: 0.0709352845097292 },
    inactive: true,
  },
  {
    index: 34,
    contractAddress: {
      [ChainId.BSC]: '0xb0992d86e7d4de0200c463e6451cce1f2775903b',
    },
    billType: 'FOXLAYER Bill',
    billVersion: BillVersion.V1,
    token: tokens.busd,
    quoteToken: tokens.usdc,
    lpToken: tokens.usdcBusd,
    earnToken: tokens.foxlayer,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://foxswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    initTime: { [ChainId.BSC]: 1668467108 },
    initPrice: { [ChainId.BSC]: 0.0709352845097292 },
    inactive: true,
  },
  {
    index: 35,
    contractAddress: {
      [ChainId.BSC]: '0x08dd5a43e5fc889f2100023f8296e14d9fd40064',
    },
    billType: 'FOXLAYER Bill',
    billVersion: BillVersion.V1,
    token: tokens.eth,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbEth,
    earnToken: tokens.foxlayer,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://foxswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    initTime: { [ChainId.BSC]: 1668467108 },
    initPrice: { [ChainId.BSC]: 0.0709352845097292 },
    inactive: true,
  },
  {
    index: 36,
    contractAddress: {
      [ChainId.BSC]: '0x069002be4ec72333a3fa8a5730e8b84680a190a4',
    },
    billType: 'FOXLAYER Bill',
    billVersion: BillVersion.V1,
    token: tokens.btc,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbBtc,
    earnToken: tokens.foxlayer,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://foxswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    initTime: { [ChainId.BSC]: 1668467108 },
    initPrice: { [ChainId.BSC]: 0.0709352845097292 },
    inactive: true,
  },

  /*
    Polygon Bills
  */
  {
    index: 106,
    contractAddress: {
      [ChainId.MATIC]: '0x220A6561490eE6926F4dBE8416E5cb0C2277a13D',
    },
    billType: 'Jungle Bill',
    billVersion: BillVersion.V2,
    token: tokens.dogira,
    quoteToken: tokens.wmatic,
    lpToken: tokens.dogiraMatic,
    earnToken: tokens.dogira,
    billNnftAddress: {
      [ChainId.MATIC]: '0x8860ABc05FefEF93306c8d65a5eFD421F155d67E',
    },
    inactive: false,
    projectLink: 'https://www.dogira.net/',
    twitter: 'https://twitter.com/DogiraOfficial',
    audit: 'https://www.certik.com/address/0x47c5aa82fda7a79c7965beb6d7c6a265fe59921b',
    initTime: { [ChainId.MATIC]: 1679498540 },
    initPrice: { [ChainId.MATIC]: 0.000939 },
  },
  {
    index: 105,
    contractAddress: {
      [ChainId.MATIC]: '0xB226290a0078a18b0A2a3BDA7460b82b966B656a',
    },
    billType: 'Jungle Bill',
    billVersion: BillVersion.V2,
    token: tokens.leap,
    quoteToken: tokens.wmatic,
    lpToken: tokens.leapMatic,
    earnToken: tokens.leap,
    billNnftAddress: {
      [ChainId.MATIC]: '0x8860ABc05FefEF93306c8d65a5eFD421F155d67E',
    },
    inactive: false,
    projectLink: 'https://www.leaptoken.io/',
    twitter: 'https://twitter.com/Play_Leap',
    audit: 'https://github.com/HashEx/public_audits/blob/master/PlayLeap/PlayLeap.pdf',
    initTime: { [ChainId.MATIC]: 1679389058 },
    initPrice: { [ChainId.MATIC]: 0.021342 },
  },
  {
    index: 104,
    contractAddress: {
      [ChainId.MATIC]: '0x66466E907B63D5c7a7Dd560e490adB596DFDf61B',
    },
    billType: 'Jungle Bill',
    billVersion: BillVersion.V2,
    token: tokens.usdc,
    quoteToken: tokens.cgg,
    lpToken: tokens.cggUsdc,
    earnToken: tokens.usdc,
    billNnftAddress: {
      [ChainId.MATIC]: '0x8860ABc05FefEF93306c8d65a5eFD421F155d67E',
    },
    inactive: false,
    projectLink: 'https://chainguardians.io/',
    twitter: 'https://twitter.com/chain_guardians',
    audit: 'https://www.certik.com/projects/chainguardians?utm_source=CMC&utm_campaign=AuditByCertiKLink',
    initTime: { [ChainId.MATIC]: 1676626187 },
    initPrice: { [ChainId.MATIC]: 0.077899 },
  },
  {
    index: 103,
    contractAddress: {
      [ChainId.MATIC]: '0xb53b2706897a26fec9113f3e2371ac5b2b88970a',
    },
    billType: 'Jungle Bill',
    billVersion: BillVersion.V1,
    token: tokens.axn,
    quoteToken: tokens.usdc,
    lpToken: tokens.axnUsdc,
    earnToken: tokens.axn,
    billNnftAddress: {
      [ChainId.MATIC]: '0xa35c3fcd306cf69fed19e8c7b15c8b3904ea609d',
    },
    inactive: false,
    projectLink: 'https://axion.network/',
    twitter: 'https://twitter.com/axion_network',
    initTime: { [ChainId.MATIC]: 1669973048 },
    initPrice: { [ChainId.MATIC]: 0.000015665249761458396 },
  },
  {
    index: 100,
    contractAddress: {
      [ChainId.MATIC]: '0x643a6848a3906182a94ec857a41c1735664c677f',
    },
    billType: 'Jungle Bill',
    billVersion: BillVersion.V1,
    token: tokens.dogira,
    quoteToken: tokens.wmatic,
    lpToken: tokens.dogiraMatic,
    earnToken: tokens.dogira,
    billNnftAddress: {
      [ChainId.MATIC]: '0xa35c3fcd306cf69fed19e8c7b15c8b3904ea609d',
    },
    projectLink: 'https://www.dogira.net/',
    twitter: 'https://twitter.com/DogiraOfficial',
    inactive: true,
  },
  {
    index: 101,
    contractAddress: {
      [ChainId.MATIC]: '0xca48704b19a053ebb5a077c215dcb4be745c9fe5',
    },
    billType: 'Jungle Bill',
    billVersion: BillVersion.V1,
    token: tokens.traxx,
    quoteToken: tokens.wmatic,
    lpToken: tokens.traxxMatic,
    earnToken: tokens.traxx,
    billNnftAddress: {
      [ChainId.MATIC]: '0xa35c3fcd306cf69fed19e8c7b15c8b3904ea609d',
    },
    inactive: false,
    projectLink: 'https://www.tokentraxx.com/',
    twitter: 'https://twitter.com/TokenTraxx',
    initTime: { [ChainId.MATIC]: 1663846458 },
    initPrice: { [ChainId.MATIC]: 0.18650580540316708 },
  },
  {
    index: 102,
    contractAddress: {
      [ChainId.MATIC]: '0xc16cf0e4c090409765757e5b13afdfc500148678',
    },
    billType: 'Jungle Bill',
    billVersion: BillVersion.V1,
    token: tokens.dogira,
    quoteToken: tokens.wmatic,
    lpToken: tokens.dogiraMatic,
    earnToken: tokens.dogira,
    billNnftAddress: {
      [ChainId.MATIC]: '0xa35c3fcd306cf69fed19e8c7b15c8b3904ea609d',
    },
    inactive: false,
    projectLink: 'https://www.dogira.net/',
    twitter: 'https://twitter.com/DogiraOfficial',
    initTime: { [ChainId.MATIC]: 1664969658 },
    initPrice: { [ChainId.MATIC]: 0.0015076537321486155 },
  },

  /*
    Telos Bills
  */
  {
    index: 211,
    contractAddress: {
      [ChainId.TLOS]: '0x84662B6bBef1E81aBcAa8372678ADBbA49d07C2c',
    },
    billType: 'Jungle Bill',
    billVersion: BillVersion.V2,
    token: tokens.foxlayer,
    quoteToken: tokens.tlos,
    lpToken: tokens.foxlayerTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x29DD194bDc735A87637f2736A90AA585B74F5FD4',
    },
    inactive: false,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
    initTime: { [ChainId.TLOS]: 1676929798 },
    initPrice: { [ChainId.TLOS]: 0.2206 },
  },
  {
    index: 212,
    contractAddress: {
      [ChainId.TLOS]: '0x5d72Be8B3E8F07f9D41944a148a109F18Cb65f55',
    },
    billType: 'Jungle Bill',
    billVersion: BillVersion.V2,
    token: tokens.eth,
    quoteToken: tokens.tlos,
    lpToken: tokens.ethTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x29DD194bDc735A87637f2736A90AA585B74F5FD4',
    },
    inactive: false,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
    initTime: { [ChainId.TLOS]: 1676929798 },
    initPrice: { [ChainId.TLOS]: 0.2206 },
  },
  {
    index: 213,
    contractAddress: {
      [ChainId.TLOS]: '0xF5F350ddcC3DE0733B62E71844A9C8BE4032DE52',
    },
    billType: 'Jungle Bill',
    billVersion: BillVersion.V2,
    token: tokens.btc,
    quoteToken: tokens.tlos,
    lpToken: tokens.btcTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x29DD194bDc735A87637f2736A90AA585B74F5FD4',
    },
    inactive: false,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
    initTime: { [ChainId.TLOS]: 1676929798 },
    initPrice: { [ChainId.TLOS]: 0.2206 },
  },
  {
    index: 214,
    contractAddress: {
      [ChainId.TLOS]: '0xE5325b9F69255098786908c79A234584FfC62dcb',
    },
    billType: 'Jungle Bill',
    billVersion: BillVersion.V2,
    token: tokens.usdt,
    quoteToken: tokens.tlos,
    lpToken: tokens.usdtTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x29DD194bDc735A87637f2736A90AA585B74F5FD4',
    },
    inactive: false,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
    initTime: { [ChainId.TLOS]: 1676929798 },
    initPrice: { [ChainId.TLOS]: 0.2206 },
  },
  {
    index: 215,
    contractAddress: {
      [ChainId.TLOS]: '0x07273ae6E441491fc4410aE9F8371AEdd7A19499',
    },
    billType: 'Jungle Bill',
    billVersion: BillVersion.V2,
    token: tokens.usdc,
    quoteToken: tokens.tlos,
    lpToken: tokens.usdcTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x29DD194bDc735A87637f2736A90AA585B74F5FD4',
    },
    inactive: false,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
    initTime: { [ChainId.TLOS]: 1676929798 },
    initPrice: { [ChainId.TLOS]: 0.2206 },
  },
  {
    index: 206,
    contractAddress: {
      [ChainId.TLOS]: '0x1Bb2Eb63c2B2CD1cE5887B4628694276E637BeD7',
    },
    billType: 'Jungle Bill',
    billVersion: BillVersion.V1,
    token: tokens.foxlayer,
    quoteToken: tokens.tlos,
    lpToken: tokens.foxlayerTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x9084C442286E23617694101d5575bEA48C2cf621',
    },
    inactive: false,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
    initTime: { [ChainId.TLOS]: 1668849848 },
    initPrice: { [ChainId.TLOS]: 0.1743935819281813 },
  },
  {
    index: 207,
    contractAddress: {
      [ChainId.TLOS]: '0xe3725Eccd93bb544b360BB572893eF17E42219c4',
    },
    billType: 'Jungle Bill',
    billVersion: BillVersion.V1,
    token: tokens.eth,
    quoteToken: tokens.tlos,
    lpToken: tokens.ethTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x9084C442286E23617694101d5575bEA48C2cf621',
    },
    inactive: false,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
    initTime: { [ChainId.TLOS]: 1668849848 },
    initPrice: { [ChainId.TLOS]: 0.1743935819281813 },
  },
  {
    index: 208,
    contractAddress: {
      [ChainId.TLOS]: '0x81f2BA390934df655eABcc3787F4f5f694e722ED',
    },
    billType: 'Jungle Bill',
    billVersion: BillVersion.V1,
    token: tokens.btc,
    quoteToken: tokens.tlos,
    lpToken: tokens.btcTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x9084C442286E23617694101d5575bEA48C2cf621',
    },
    inactive: false,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
    initTime: { [ChainId.TLOS]: 1668849848 },
    initPrice: { [ChainId.TLOS]: 0.1743935819281813 },
  },
  {
    index: 209,
    contractAddress: {
      [ChainId.TLOS]: '0x68aEaAbE6EbBd2b5Cf0130d0BAAef1Ec5E0539Aa',
    },
    billType: 'Jungle Bill',
    billVersion: BillVersion.V1,
    token: tokens.usdt,
    quoteToken: tokens.tlos,
    lpToken: tokens.usdtTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x9084C442286E23617694101d5575bEA48C2cf621',
    },
    inactive: false,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
    initTime: { [ChainId.TLOS]: 1668849848 },
    initPrice: { [ChainId.TLOS]: 0.1743935819281813 },
  },
  {
    index: 210,
    contractAddress: {
      [ChainId.TLOS]: '0xb3a0b38E5C07db5238538a640155562085F22bCe',
    },
    billType: 'Jungle Bill',
    billVersion: BillVersion.V1,
    token: tokens.usdc,
    quoteToken: tokens.tlos,
    lpToken: tokens.usdcTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x9084C442286E23617694101d5575bEA48C2cf621',
    },
    inactive: false,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
    initTime: { [ChainId.TLOS]: 1668849848 },
    initPrice: { [ChainId.TLOS]: 0.1743935819281813 },
  },
  {
    index: 201,
    contractAddress: {
      [ChainId.TLOS]: '0x7C02E197BC2644FE0E9834641F80aC0A15055ad7',
    },
    billType: 'Jungle Bill',
    billVersion: BillVersion.V1,
    token: tokens.foxlayer,
    quoteToken: tokens.tlos,
    lpToken: tokens.foxlayerTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x9084C442286E23617694101d5575bEA48C2cf621',
    },
    inactive: true,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
  },
  {
    index: 202,
    contractAddress: {
      [ChainId.TLOS]: '0x76a33a5d002C711A3F353cB4Ee2B112B579c317a',
    },
    billType: 'Jungle Bill',
    billVersion: BillVersion.V1,
    token: tokens.eth,
    quoteToken: tokens.tlos,
    lpToken: tokens.ethTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x9084C442286E23617694101d5575bEA48C2cf621',
    },
    inactive: true,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
  },
  {
    index: 203,
    contractAddress: {
      [ChainId.TLOS]: '0xa08A0125F4B85320e2357174EDC9E262098BF850',
    },
    billType: 'Jungle Bill',
    billVersion: BillVersion.V1,
    token: tokens.btc,
    quoteToken: tokens.tlos,
    lpToken: tokens.btcTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x9084C442286E23617694101d5575bEA48C2cf621',
    },
    inactive: true,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
  },
  {
    index: 204,
    contractAddress: {
      [ChainId.TLOS]: '0xD1FbE4EAFAcB7FdC56F7Aa8dA24198b01942C677',
    },
    billType: 'Jungle Bill',
    billVersion: BillVersion.V1,
    token: tokens.usdt,
    quoteToken: tokens.tlos,
    lpToken: tokens.usdtTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x9084C442286E23617694101d5575bEA48C2cf621',
    },
    inactive: true,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
  },
  {
    index: 205,
    contractAddress: {
      [ChainId.TLOS]: '0xc17a1ea1Da9975A2fad85eC7C39AEBA2B4BfEA11',
    },
    billType: 'Jungle Bill',
    billVersion: BillVersion.V1,
    token: tokens.usdc,
    quoteToken: tokens.tlos,
    lpToken: tokens.usdcTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x9084C442286E23617694101d5575bEA48C2cf621',
    },
    inactive: true,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
  },
  /*
      Arbitrum Bills - ID started from 2001
  */
  {
    index: 2001,
    contractAddress: {
      [ChainId.ARBITRUM]: '0x9Bd72c6c69cC314AdfF396cC2b34fF7e7fbd68ad',
    },
    billType: 'JUNGLE Bill',
    billVersion: BillVersion.V2,
    token: tokens.onyx,
    quoteToken: tokens.eth,
    lpToken: tokens.onyxEth,
    earnToken: tokens.onyx,
    billNnftAddress: {
      [ChainId.ARBITRUM]: '0xAC707999D05C4048844B78c737D9512DFDff524d',
    },
    projectLink: 'https://www.onyxdao.finance/',
    twitter: 'https://twitter.com/OnyxDAO',
    audit: 'https://hashex.org/audits/onyx-dao/',
    initTime: { [ChainId.BSC]: 1679952396 },
    initPrice: { [ChainId.BSC]: 1.5528 },
  },
]

export default bills
